import axios from 'axios';
import api from '../axios';
const resource = '/api/mp/';

export default {
  getPracticeLeaves: (paginate, page) =>
    api.get(
      `${resource}salaried-staff/leaves/practice-leaves?page=${page}&paginate=${paginate}`
    ),
  getTodaysLeaves: (paginate, page) =>
    api.get(
      `${resource}salaried-staff/leaves/todays-leaves?page=${page}&paginate=${paginate}`
    ),
  getScheduledLeaves: (paginate, page) =>
    api.get(
      `${resource}salaried-staff/leaves/scheduled-leaves?page=${page}&paginate=${paginate}`
    ),
  usersPastLeaves: (id, paginate, page) =>
    api.get(
      `${resource}salaried-staff/leaves/past-leaves/${id}/?page=${page}&paginate=${paginate}`
    ),
  usersScheduledLeaves: (id, paginate, page) =>
    api.get(
      `${resource}salaried-staff/leaves/scheduled-leaves/${id}/?page=${page}&paginate=${paginate}`
    ),
  cancelLeave: (id) =>
    api.post(`${resource}salaried-staff/leaves/${id}/cancel`),
  requestLeave: (payload) =>
    api.post(`${resource}salaried-staff/leaves/request-leave`, payload),

  userSummary: (id) => api.get(`${resource}salaried-staff/leaves/user-summary/${id}`),
  updateLeaves: (id, payload) =>
    api.put(`${resource}salaried-staff/leaves/edit-leave/${id}`, payload),
  getGraphDetails: () => api.get(`${resource}salaried-staff/leaves/graph`),
  getUKPublicHolidays: () =>
    api.get(`${resource}salaried-staff/leaves/bank-holidays`),
  getLeaveReasons: () => api.get(`${resource}salaried-staff/leaves/reasons`),
  getSicknessTypes: () =>
    api.get(`${resource}salaried-staff/leaves/sickness-types`),
  getUserWorkingHours: () => api.get(`/api/profile/working-hours`),
};
